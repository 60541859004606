<template>
  <v-card>
    <v-card-title>
      <div>
        <div>Performance</div>
        <div class="subtitle-1 light-grey">Last 30 days</div>
      </div>
    </v-card-title>
    <v-card-text class="p-1">
      <v-container fluid grid-list-sm class="p-0">
        <v-layout wrap>
          <v-flex xs12 sm4>
            <v-list-item>
              <v-list-item-icon class="mr-3">
                <v-icon>fal fa-hand-point-up</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ stats.clicks }}</v-list-item-title>
                <v-list-item-subtitle>
                  <router-link :to="{name: 'report.clicks', query: {business_id: params.business_uuid, start_date: params.start_date, end_date: params.end_date}}">
                    Clicks
                  </router-link>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 sm4>
            <v-list-item>
              <v-list-item-icon class="mr-3">
                <v-icon>fal fa-fingerprint</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ stats.unique_clicks }}</v-list-item-title>
                <v-list-item-subtitle>
                  <router-link :to="{name: 'report.clicks', query: {business_id: params.business_uuid, start_date: params.start_date, end_date: params.end_date}}">
                    Unique clicks
                  </router-link>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 sm4>
            <v-list-item>
              <v-list-item-icon class="mr-3">
                <v-icon>fal fa-shopping-basket</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stats.sales }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <router-link :to="{name: 'report.sales', query: params}">
                    Conversions
                  </router-link>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 sm4>
            <v-list-item>
              <v-list-item-icon class="mr-3">
                <v-icon>fal fa-coins</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>DKK {{ stats.value | local_numbers }}</v-list-item-title>
                <v-list-item-subtitle>Revenue</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 sm4>
            <v-list-item>
              <v-list-item-icon class="mr-3">
                <v-icon>fal fa-money-bill-wave</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>DKK {{ stats.commission | local_numbers }}</v-list-item-title>
                <v-list-item-subtitle>
                  <router-link :to="{name: 'report.commission', query: {business_id: params.business_uuid, start_date: params.start_date, end_date: params.end_date}}">
                    Commission
                  </router-link>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 sm4>
            <v-list-item>
              <v-list-item-icon class="mr-3">
                <v-icon>fal fa-percentage</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ stats.conversion }}%</v-list-item-title>
                <v-list-item-subtitle
                >Conversion rate</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import {mapActions} from "vuex";

export default {
  filters: {
    local_numbers
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    stats: {}
  }),
  methods: {
    ...mapActions('core', ['getStats']),
    load() {
      this.getStats(this.params).then(stats => {
        this.stats = stats[0];
      });
    }
  },
  watch: {
    params: {
      immediate: true,
      handler() {
        this.load();
      }
    }
  }
};
</script>
