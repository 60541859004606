<template>
  <div>
    <v-card flat>
      <v-card-title class="business-card-title">
        <div>
          <div>Campaigns</div>
          <div class="subtitle-1 light-grey">Most recent campaigns</div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="campaigns"
          disable-sort
          :loading="loading"
          hide-default-footer
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.uuid">
                <td style="max-width: 15px">
                  <campaign-status-icon :value="item"></campaign-status-icon>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'campaign', params: { id: item.uuid } }"
                  >
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  {{ item.starting_at | utc_to_local_datetime("DD MMM YYYY") }}
                </td>
                <td>
                  {{ item.ending_at | utc_to_local_datetime("DD MMM YYYY") }}
                </td>
                <td>
                  <template>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                          <v-icon class="mt-1 menu-icon" v-if="item.hidden"
                            >fal fa-eye-slash</v-icon
                          >
                          <v-icon class="mt-1 menu-icon" v-else
                            >fal fa-eye</v-icon
                          >
                        </v-list-item-icon>
                      </template>
                      <span v-if="item.hidden">Private</span>
                      <span v-else>Public</span>
                    </v-tooltip>
                  </template>
                </td>
                <td>
                  {{ activeInfluencers(item) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" outlined :to="{name: 'campaigns', query: {business_uuid: businessId}}">
          See all
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import CampaignStatusIcon from "../../icons/CampaignStatusIcon";

export default {
  components: {CampaignStatusIcon},
  props: {
    businessId: {
      required: true,
      type: String
    }
  },
  filters: {
    utc_to_local_datetime
  },
  data: () => ({
    campaigns: [],
    loading: true,
    total: null,
    page: 1,
    headers: [
      {},
      {
        text: "Name",
        sortable: false
      },
      {
        text: "Starting",
        sortable: false
      },
      {
        text: "Ending",
        sortable: false
      },
      {
        text: "Visibility",
        sortable: false
      },
      {
        text: "Active influencers",
        sortable: false
      }
    ]
  }),
  computed: {},
  methods: {
    ...mapActions("core/campaigns", ["loadCampaigns"]),
    getCampaigns(page = 1) {
      this.page = page;
      this.campaigns = [];
      const params = {
        business_uuid: this.businessId,
        draft: false,
        page: this.page,
        limit: 5,
        with: ["influencers"]
      };
      this.loadCampaigns(params).then(campaigns => {
        this.loading = false;
        this.total = campaigns.total;
        this.campaigns = campaigns.response;
      });
    },
    activeInfluencers(campaign) {
      return campaign.participants;
    }
  },
  created() {
    this.getCampaigns();
  }
};
</script>
