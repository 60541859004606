<template>
  <div>
    <v-container
      v-if="business.uuid !== 'undefined'"
      fluid
      grid-list-xl
      class="p-0"
    >
      <v-layout wrap>
        <v-flex xs12 sm8>
          <div class="headline">Business profile</div>
        </v-flex>
        <v-flex xs12 sm4 class="text-right">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                :block="$vuetify.breakpoint.xs"
              >
                Action
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  class="action-btn cursor-pointer"
                  @click="showConfirmV1ShadowLoginDialog = true"
                >
                  Shadow log in (V1)
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title
                  class="action-btn cursor-pointer"
                  @click="showConfirmV2ShadowLoginDialog = true"
                >
                  Shadow log in (Beta)
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs12 sm4>
          <v-card>
            <v-card-title>
              <div>
                <div>{{ business.display_name }}</div>
                <div class="subtitle-1 light-grey">CVR {{ business.vat }}</div>
              </div>
            </v-card-title>
            <v-card-text class="p-1">
              <v-list subheader two-line>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      Primary contact
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ business.user.first_name }}
                      {{ business.user.last_name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      Email
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <a :href="'mailto:' + business.user.email">
                        {{ business.user.email }}
                      </a>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.user.email = business.user.email;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Email"
                            v-model="tempBusiness.user.email"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editUserData({
                                  first_name: tempBusiness.user.first_name,
                                  last_name: tempBusiness.user.last_name,
                                  email: tempBusiness.user.email,
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Phone</v-list-item-title>
                    <v-list-item-subtitle>
                      <a :href="'tel:+45' + business.phone">
                        {{ business.phone }}
                      </a>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.phone = business.phone;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Phone"
                            v-model="tempBusiness.phone"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({ phone: tempBusiness.phone })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Website</v-list-item-title>
                    <v-list-item-subtitle>
                      <a :href="business.website">
                        {{ business.website }}
                      </a>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.website = business.website;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Website"
                            v-model="tempBusiness.website"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  website: tempBusiness.website,
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Category</v-list-item-title>
                    <v-list-item-subtitle
                      v-if="business.channel_category === null"
                    >
                      -
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <v-icon
                        small
                        color="grey"
                        v-text="business.channel_category.icon"
                      />
                      {{ business.channel_category.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="300px"
                        v-click-outside="
                          () => {
                            tempBusiness.channel_category_id =
                              business.channel_category_id;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <channel-category-select
                            v-model="tempBusiness.channel_category_id"
                            :return-object="false"
                            :multiple="false"
                          ></channel-category-select>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  channel_category_id:
                                    tempBusiness.channel_category_id,
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <v-card class="mt-4">
            <v-card-title>
              Agency
            </v-card-title>
            <v-card-text class="p-1">
              <v-list subheader two-line>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Agency</v-list-item-title>
                    <v-list-item-subtitle v-if="business.agency === null">
                      -
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      {{ business.agency.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="300px"
                        v-click-outside="
                          () => {
                            tempBusiness.agency_uuid = business.agency_uuid;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <agencies-select
                            v-model="tempBusiness.agency_uuid"
                            :return-object="false"
                            clearable
                          ></agencies-select>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  agency_uuid: tempBusiness.agency_uuid
                                    ? tempBusiness.agency_uuid
                                    : '',
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <v-card class="mt-4">
            <v-card-title>
              Subscription
            </v-card-title>
            <v-card-text class="p-1">
              <v-list subheader two-line>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Card attached</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ business.user.card_last_four !== null ? "Yes" : "-" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title
                      >Allowed active influencers</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ business.allowed_active_influencers }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.allowed_active_campaigns =
                              business.allowed_active_campaigns;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Allowed active influencers"
                            v-model="tempBusiness.allowed_active_influencers"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  allowed_active_influencers: parseInt(
                                    tempBusiness.allowed_active_influencers
                                  ),
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title
                      >Allowed active campaigns</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ business.allowed_active_campaigns }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.allowed_active_campaigns =
                              business.allowed_active_campaigns;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Allowed active influencers"
                            v-model="tempBusiness.allowed_active_campaigns"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  allowed_active_campaigns: parseInt(
                                    tempBusiness.allowed_active_campaigns
                                  ),
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Status</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ business.subscriptionStatus }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Product</v-list-item-title>
                    <v-list-item-subtitle>{{
                      business.user.active_subscription
                        ? business.user.active_subscription.product.display_name
                        : "-"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Price</v-list-item-title>
                    <v-list-item-subtitle>{{
                      business.user.active_subscription &&
                      business.user.active_subscription.product.price
                        ? business.user.active_subscription.product.price.name
                        : "-"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Contract End</v-list-item-title>
                    <v-list-item-subtitle>
                      <span
                        v-if="
                          business.user.active_subscription &&
                            business.user.active_subscription.contract_ending_at
                        "
                        >{{
                          business.user.active_subscription.contract_ending_at
                            | utc_to_local_datetime
                        }}</span
                      >
                      <span v-else>-</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="businessSubscriptionIsActive"
                @click="showSubscriptionCancelDialog = true"
                x-small
                text
                block
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>

          <v-card class="mt-4">
            <v-card-title>
              <div>
                <div>Tracking</div>
                <span class="subtitle-2 light-grey">
                  <router-link
                    :to="{
                      name: 'conversion_logs',
                      query: { search: business.uuid },
                    }"
                  >
                    See conversion logs
                  </router-link>
                </span>
              </div>
            </v-card-title>
            <v-card-text class="p-1">
              <v-list subheader two-line>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Webshop system</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ business.webshop_system }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.webshop_system =
                              business.webshop_system;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Website"
                            v-model="tempBusiness.webshop_system"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  webshop_system: tempBusiness.webshop_system,
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Approval days</v-list-item-title>
                    <v-list-item-subtitle>{{
                      business.approval_days
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.approval_days = business.approval_days;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Website"
                            v-model="tempBusiness.approval_days"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  approval_days: parseInt(
                                    tempBusiness.approval_days
                                  ),
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Service fee</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ business.service_fee }}%
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu left offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon small color="grey lighten-1"
                            >fal fa-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card
                        max-width="200px"
                        v-click-outside="
                          () => {
                            tempBusiness.service_fee = business.service_fee;
                          }
                        "
                      >
                        <v-card-text class="p-2">
                          <v-text-field
                            placeholder="Website"
                            v-model="tempBusiness.service_fee"
                            dense
                            outlined
                          ></v-text-field>

                          <div class="text-right">
                            <v-btn
                              color="primary"
                              small
                              @click="
                                editBusinessData({
                                  service_fee: parseInt(
                                    tempBusiness.service_fee
                                  ),
                                })
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Tracking level</v-list-item-title>
                    <v-list-item-subtitle>{{
                      businessTrackingLevel
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-subheader>Tracking code</v-subheader>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Approved</v-list-item-title>
                    <v-list-item-subtitle
                      v-if="business.pixel_approved_at !== null"
                    >
                      {{ business.pixel_approved_at }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      -
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      v-if="business.pixel_approved_at !== null"
                      icon
                      small
                      @click="showConfirmRemoveTrackingDialog = true"
                    >
                      <v-icon small color="grey lighten-1"
                        >fal fa-pencil</v-icon
                      >
                    </v-btn>
                    <v-btn
                      v-else
                      icon
                      small
                      :to="{
                        name: 'application.business',
                        params: { id: this.businessId },
                      }"
                    >
                      <v-icon small color="grey lighten-1"
                        >fal fa-pencil</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>Promotion code</v-list-item-title>
                    <v-list-item-subtitle
                      v-if="
                        business.promotion_code_tracking_approved_at !== null
                      "
                    >
                      {{ business.promotion_code_tracking_approved_at }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      -
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      v-if="
                        business.promotion_code_tracking_approved_at !== null
                      "
                      icon
                      small
                      @click="showConfirmRemovePromoTrackingDialog = true"
                    >
                      <v-icon small color="grey lighten-1"
                        >fal fa-pencil</v-icon
                      >
                    </v-btn>
                    <v-btn
                      v-else
                      icon
                      small
                      :to="{
                        name: 'application.business',
                        params: { id: this.businessId },
                      }"
                    >
                      <v-icon small color="grey lighten-1"
                        >fal fa-pencil</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <v-card class="mt-4">
            <v-card-title>
              <div>
                <div>Commission</div>
                <v-btn
                  v-if="isFinanceUser"
                  small
                  text
                  color="primary"
                  @click="showForceInvoiceDialog = true"
                >
                  Force invoice
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text class="p-1">
              <v-list subheader two-line>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-certificate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>New</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.new
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-badge-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Approved</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.approved
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-receipt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Invoiced (Not paid)</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.invoiced
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-hourglass</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Awaiting transfer</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.pending_payout
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 sm8>
          <business-campaign-card
            :business-id="businessId"
          ></business-campaign-card>
          <top-stats-card
            :params="topStatsParams"
            class="mt-4"
          ></top-stats-card>
          <top-influencers-card
            :influencers="topInfluencers"
            class="mt-4"
          ></top-influencers-card>
          <invoices-card
            :business="business"
            :invoices="invoices"
            class="mt-4"
          ></invoices-card>
        </v-flex>
      </v-layout>

      <confirm-dialog
        v-model="showConfirmV1ShadowLoginDialog"
        title="Confirm shadow login (V1)"
        @confirm="setV1ShadowLogin()"
      ></confirm-dialog>
      <confirm-dialog
        v-model="showConfirmV2ShadowLoginDialog"
        title="Confirm shadow login (Beta)"
        @confirm="setV2ShadowLogin()"
      ></confirm-dialog>
      <confirm-dialog
        v-model="showConfirmRemoveTrackingDialog"
        title="Remove tracking"
        @confirm="
          editBusinessData({ reset_pixel_approved_at: true });
          business.pixel_approved_at = null;
          business.promotion_code_tracking_approved_at = null;
        "
      ></confirm-dialog>
      <confirm-dialog
        v-model="showConfirmRemovePromoTrackingDialog"
        title="Remove promotion tracking"
        @confirm="
          editBusinessData({ reset_promotion_code_tracking_approved_at: true });
          business.promotion_code_tracking_approved_at = null;
        "
      ></confirm-dialog>

      <v-dialog v-model="showForceInvoiceDialog" max-width="550px">
        <v-card>
          <v-card-title>
            <div>
              <div>Force invoice commission</div>
              <div class="subtitle-2 light-grey">
                This is only visible for Finance users
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            Invoice all <u>new</u> and <u>approved</u> commission,
            until/including the date selected
            <br />

            Will only invoice if minimum 50,- available (Existing flow)

            <date-picker
              v-model="forceInvoiceDate"
              label="Invoice commission until/including"
            ></date-picker>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showForceInvoiceDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="error"
              text
              :disabled="forceInvoiceDate === null"
              @click="forceInvoice()"
              >Invoice</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showSubscriptionCancelDialog" max-width="550px">
        <v-card>
          <v-card-title>
            <div>
              <div>Cancel subscription</div>
              <div class="subtitle-2 light-grey"></div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="cancelReason"
              :items="cancelReasonsReverse"
              label="Cancel reason"
            ></v-select>

            <v-textarea
              label="Cancel reason text"
              v-model="cancelReasonText"
              :counter="500"
            />

            <v-checkbox
              v-model="cancelAtPeriodEnd"
              :label="'Cancel at period end? (' + periodEnd + ')'"
            ></v-checkbox>

            <v-text-field
              v-if="cancelReason"
              v-model="cancelConfirm"
              label="Write 'cancel' to confirm cancellation"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showSubscriptionCancelDialog = false"
              >Go back</v-btn
            >
            <v-btn
              text
              :loading="cancelLoading"
              :disabled="
                (cancelReason === 'other' && cancelReasonText.length < 3) ||
                  !cancelReason ||
                  cancelConfirm !== 'cancel'
              "
              @click="stopSubscription()"
              >Cancel Subscription</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container v-else>
      Loading..
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import local_numbers from "../../../helpers/filters/local_numbers";
import utc_to_local_datetime from "../../../helpers/filters/utc_to_local_datetime";

import TopInfluencersCard from "@/components/moderator/cards/TopInfluencersCard";
import InvoicesCard from "@/components/moderator/cards/InvoicesCard";
import BusinessCampaignCard from "@/components/moderator/cards/BusinessCampaignCard";
import TopStatsCard from "../cards/TopStatsCard";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import ChannelCategorySelect from "../../selects/ChannelCategorySelect";
import AgenciesSelect from "../selects/AgenciesSelect";
import DatePicker from "../../common/filters/DatePicker";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime,
  },
  components: {
    DatePicker,
    AgenciesSelect,
    ChannelCategorySelect,
    ConfirmDialog,
    TopStatsCard,
    TopInfluencersCard,
    InvoicesCard,
    BusinessCampaignCard,
  },
  data: () => ({
    businessId: null,
    business: {
      user: {},
      channel_category: {},
      agency: {},
    },
    tempBusiness: {
      user: {},
      channel_category: {},
      agency: {},
    },
    startDate: "",
    endDate: "",
    comissionStatuses: {},
    topInfluencers: [],
    invoices: [],
    showConfirmV1ShadowLoginDialog: false,
    showConfirmV2ShadowLoginDialog: false,
    showConfirmRemoveTrackingDialog: false,
    showConfirmRemovePromoTrackingDialog: false,
    showForceInvoiceDialog: false,
    forceInvoiceDate: null,
    showSubscriptionCancelDialog: false,
    cancelConfirm: "",
    cancelLoading: false,
    cancelAtPeriodEnd: true,
    cancelReason: "",
    cancelReasonText: "",
    cancelReasons: [
      { value: "lacking_time", text: "Lacking time" },
      {
        value: "pausing_influencer_marketing",
        text: "Pausing influencer marketing",
      },
      {
        value: "influencers_not_active",
        text: "Influencers not active",
      },
      {
        value: "trouble_finding_influencers",
        text: "Trouble finding influencers",
      },
      {
        value: "our_product_doesnt_fit",
        text: "Our product doesn't fit",
      },
      {
        value: "platform_hard_to_understand",
        text: "The platform is hard to understand",
      },
      {
        value: "expected_more_service",
        text: "Not enough influencers",
      },
      {
        value: "too_many_products",
        text: "Had to send too many products to influencers",
      },
      {
        value: "not_enough_conversions",
        text: "Not enough conversions",
      },
    ],
  }),
  computed: {
    ...mapGetters("core/auth", ["isFinanceUser"]),
    topStatsParams() {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        business_uuid: this.businessId,
      };
    },
    businessTrackingLevel() {
      switch (this.business.tracking_level) {
        case 1:
          return "IP";
        case 2:
          return "IP+Device";
        case 3:
          return "IP+Device+Version";
        default:
          return "-";
      }
    },
    cancelReasonsReverse() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let reasons = this.cancelReasons.reverse();
      reasons.push({ value: "other", text: "Other" });
      return reasons;
    },
    periodEnd() {
      if (
        this.business.user.active_subscription &&
        this.business.user.active_subscription.contract_ending_at
      ) {
        return this.$options.filters.utc_to_local_datetime(
          this.business.user.active_subscription.contract_ending_at
        );
      }

      if (
        this.business.user.active_subscription &&
        this.business.user.active_subscription.next_period_at
      ) {
        return this.$options.filters.utc_to_local_datetime(
          this.business.user.active_subscription.next_period_at
        );
      }

      return null;
    },
    businessSubscriptionIsActive() {
      if (!this.business.user || !this.business.user.active_subscription) {
        return false;
      }

      if (
        !this.business.user.active_subscription.is_active ||
        this.business.user.active_subscription.is_canceled ||
        this.business.user.active_subscription.is_churned
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    ...mapActions("business", ["updateBusiness"]),
    ...mapActions("core/auth", ["shadowLogin"]),
    ...mapActions("core", [
      "getBusiness",
      "getComissionStatus",
      "getDashboardInfluencerStats",
      "getInvoices",
      "updateUserData",
      "forceInvoiceTransactions",
      "cancelSubscription",
    ]),
    getBusinessInfo() {
      this.getBusiness({ business_uuid: this.businessId }).then((business) => {
        this.business = business;
        this.tempBusiness = this.cloneDeep(this.business);
        this.business.agency_uuid = business.agency
          ? business.agency.uuid
          : null;
        this.business.channel_category_id = business.channel_category
          ? business.channel_category.id
          : null;
        this.business.user = business.user ? business.user : {};
        this.getBusinessInvoices();
      });
    },
    getComission() {
      const params = {
        business: true,
        business_uuid: this.businessId,
      };
      this.getComissionStatus(params).then((items) => {
        if (items.data.length > 0) {
          this.comissionStatuses = items.data[0];
        }
      });
    },
    getBusinessInvoices() {
      this.invoices = [];
      const params = {
        user_id: this.business.user.uuid,
        limit: 15,
      };

      this.getInvoices(params).then((invoices) => {
        this.invoices = invoices;
      });
    },
    getTopInfluencers() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        group: ["influencer"],
        business_uuid: this.businessId,
        limit: 10,
      };

      this.getDashboardInfluencerStats(params).then(
        (response) => {
          this.topInfluencers = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    refresh() {
      this.startDate = this.$moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      this.endDate = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      this.getBusinessInfo();
      this.getComission();
      this.getTopInfluencers();
    },
    setV1ShadowLogin() {
      // Get login token from BE and open new tab
      this.shadowLogin({ user_uuid: this.business.user.uuid }).then(
        (response) => {
          let redirectLink = `${process.env.VUE_APP_V1_SHADOW_LOGIN_URL}?token=${response.token}`;
          console.log("redirectLink", redirectLink);
          window.open(redirectLink, "_blank").focus();
        },
        () => {
          this.setSnackError("Failed");
        }
      );
    },
    setV2ShadowLogin() {
      // Get login token from BE and open new tab
      this.shadowLogin({ user_uuid: this.business.user.uuid }).then(
        (response) => {
          let redirectLink = `${process.env.VUE_APP_V2_SHADOW_LOGIN_URL}?token=${response.token}`;
          console.log("redirectLink", redirectLink);
          window.open(redirectLink, "_blank").focus();
        },
        () => {
          this.setSnackError("Failed");
        }
      );
    },
    editUserData(params) {
      params["uuid"] = this.business.user.uuid;
      this.updateUserData(params)
        .then(() => {
          this.setSnackSuccess("Success");
        })
        .catch(() => {
          this.setSnackError("Failed");
        });
    },
    editBusinessData(params) {
      params["business_uuid"] = this.businessId;
      this.updateBusiness(params)
        .then((data) => {
          this.business = {
            ...this.business,
            ...data,
          };
          this.tempBusiness = this.cloneDeep(this.business);
          this.setSnackSuccess("Success");
        })
        .catch((err) => {
          this.setSnackError("Failed");
          console.error(err);
        });
    },
    forceInvoice() {
      let params = {
        business_id: this.businessId,
        invoice_until_date: this.forceInvoiceDate,
      };

      this.forceInvoiceTransactions(params)
        .then(() => {
          this.showForceInvoiceDialog = false;
          this.forceInvoiceDate = null;
          this.getBusinessInvoices();
          this.getComission();
        })
        .catch(() => {
          this.setSnackError("Failed");
        });
    },
    stopSubscription() {
      this.cancelLoading = true;

      const params = {
        business_uuid: this.businessId,
        cancel_at_period_end: this.cancelAtPeriodEnd,
        cancel_reason: this.cancelReason,
        cancel_reason_text: this.cancelReasonText,
      };

      this.cancelSubscription(params).then(
        (response) => {
          if (response.error_code) {
            this.setSnackError("Something went wrong");
            this.cancelLoading = false;
          } else {
            this.showSubscriptionCancelDialog = false;
            this.cancelLoading = false;
            this.cancelReason = null;
            this.cancelReasonText = "";
            this.cancelConfirm = "";
            this.setSnackSuccess("Subscription is cancelled");
            this.setSubscription(response);
          }
        },
        (error) => {
          console.log(error);
          this.cancelLoading = false;
          this.setSnackError("Something went wrong");
        }
      );
    },
  },
  created() {
    this.businessId = this.$route.params.id;
    this.refresh();
  },
};
</script>
<style lang="scss" scoped>
.v-list--dense .v-list-item .v-list-item__content,
.v-list-item--dense .v-list-item__content {
  padding: 0;
}

.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 50px;
}
</style>
