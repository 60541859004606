<template>
  <v-dialog max-width="300" v-model="showDialog">
    <v-card>
      <v-card-title>
        <span class="headline" v-text="title"></span>
      </v-card-title>
      <v-card-text v-if="text" v-html="text"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">{{ btnCancelText }}</v-btn>
        <v-btn
          :color="color"
          :loading="loading"
          @click="$emit('confirm', true)"
          >{{ btnText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false
    },
    title: {
      required: false,
      type: String,
      default: ""
    },
    text: {
      required: false,
      type: String,
      default: ""
    },
    btnText: {
      required: false,
      type: String,
      default: "ok"
    },
    btnCancelText: {
      required: false,
      type: String,
      default: "Cancel"
    },
    color: {
      required: false,
      type: String,
      default: "primary"
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel", true);
      this.showDialog = false;
    }
  }
};
</script>
