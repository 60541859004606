<template>
  <v-select
    :items="items"
    v-model="selected"
    color="primary"
    label="Agency"
    item-text="name"
    item-value="uuid"
    :return-object="returnObject"
    :error-messages="errorMessages"
    :clearable="clearable"
  >
    <!-- Selected item -->
    <template class="" slot="selection" slot-scope="prop">
      <v-list-item-content>
        <v-list-item-title v-html="prop.item.name"></v-list-item-title>
        <v-list-item-subtitle
          class="text--secondary"
          v-html="prop.item.user.full_name"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>Intet resultat</v-list-item-title>
      </v-list-item>
    </v-list>

    <!--List item-->
    <template slot="item" slot-scope="prop">
      <v-list-item-content class="mt-1 mb-1">
        <v-list-item-title v-html="prop.item.name"></v-list-item-title>
        <v-list-item-subtitle
          class="text--secondary"
          v-html="prop.item.user.full_name"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true
    },
    errorMessages: {
      required: false,
      default: null
    },
    returnObject: {
      required: false,
      default: true
    },
    clearable: {
      required: false,
      default: false
    }
  },
  data: () => ({
    items: []
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    ...mapActions("core", ["getAgencies"])
  },
  created() {
    this.getAgencies({ first: 100 }).then(response => {
      this.items = response;
    });
  }
};
</script>
