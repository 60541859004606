<template>
  <v-dialog v-model="showing" max-width="85%">
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Opret faktura</div>
          <span class="subtitle-1 light-grey">{{ business.display_name }}</span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-btn text @click="addInvoiceLine()">Tilføj vare</v-btn>
        <v-row v-for="(line, index) in invoiceLines" :key="index">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="line.name"
              label="Vare"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="line.quantity"
              label="Antal"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="line.price"
              label="Styk pris"
              outlined
              dense
              prefix="DKK"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <!--<v-icon>fal fa-ellipsis-h</v-icon>-->
            <v-icon
              v-if="invoiceLines.length > 1"
              @click="removeInvoiceLine(index)"
              >fal fa-times</v-icon
            >
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row>
          <!--<v-col cols="12" sm="6">-->
          <!--</v-col>-->
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="6">
                <b>Subtotal</b>
              </v-col>
              <v-col cols="12" sm="6" class="text-right">
                {{ subtotal | local_numbers }}kr.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Moms</b>
              </v-col>
              <v-col cols="12" sm="6" class="text-right">
                {{ tax | local_numbers }}kr.
              </v-col>
            </v-row>
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>

            <v-row>
              <v-col cols="12" sm="6">
                <b>Total</b>
              </v-col>
              <v-col cols="12" sm="6" class="text-right">
                {{ total | local_numbers }}kr.
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="12" sm="6">
            <v-alert v-if="!business.user.card_last_four" type="warning">
              Kunden har ikke tilknyttet kort
            </v-alert>

            <v-radio-group
              v-model="collectionMethod"
              column
              label="Indkrævnings metode"
            >
              <v-radio
                label="Automatisk (Kort)"
                color="primary"
                value="charge_automatically"
                :disabled="!business.user.card_last_four"
              ></v-radio>
              <v-radio
                label="Send en mail med betalingslink"
                color="primary"
                value="send_invoice"
              ></v-radio>
            </v-radio-group>

            <v-text-field
              v-if="collectionMethod === 'send_invoice'"
              v-model="days_until_due"
              outlined
              dense
              prefix="Forfald om"
              suffix="dage"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          :loading="loading"
          :disabled="total === 0"
          @click="save()"
        >
          Opret faktura
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import { mapActions } from "vuex";

export default {
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      default: false
    },
    business: {
      required: true,
      default: null
    }
  },
  data: () => ({
    loading: false,
    invoiceLines: [],
    collectionMethod: "charge_automatically",
    days_until_due: 7
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    subtotal() {
      return this.invoiceLines.reduce(
        (accumulator, item) => accumulator + item.quantity * item.price,
        0
      );
    },
    tax() {
      return this.subtotal * 0.25;
    },
    total() {
      return this.subtotal + this.tax;
    }
  },
  methods: {
    ...mapActions("moderator", ["addInvoice"]),

    save() {
      this.loading = true;

      let params = {
        lines: this.invoiceLines.map(line => ({
          name: line.name,
          quantity: Number(line.quantity),
          price: Number(line.price)
        })),
        collection_method: this.collectionMethod,
        days_until_due: Number(this.days_until_due),
        business_id: this.business.uuid
      };
      this.addInvoice(params).then(
        () => {
          this.$emit("success");
          this.setSnackSuccess("Succes");
          this.invoiceLines = [];
          this.showing = false;
          this.loading = false;
        },
        () => {
          this.setSnackError("Noget gik galt");
          this.loading = false;
        }
      );
    },
    addInvoiceLine() {
      this.invoiceLines.push({
        name: "",
        quantity: 1,
        price: 0
      });
    },
    removeInvoiceLine(index) {
      this.invoiceLines.splice(index, 1);
    }
  },
  created() {
    this.addInvoiceLine();

    if (!this.business.user.card_last_four) {
      this.collectionMethod = "send_invoice";
    }
  }
};
</script>
