<template>
  <v-card>
    <v-card-title>
      <div>
        <div>Top influencers</div>
        <div class="subtitle-1 light-grey">Last 30 days</div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="influencers"
        hide-default-footer
        :items-per-page="10"
      >
        <v-progress-linear
          slot="progress"
          color="primary"
          indeterminate
        ></v-progress-linear>

        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
               <v-avatar style="border: 1px solid #eee" :size="32">
                <img
                    ref="avatar"
                    :src="item.influencer.user.avatar_url"
                />
                </v-avatar>
              </td>
              <td class="word-no-wrap">
                <router-link :to="{name: 'influencer', params: {id: item.influencer.uuid}}">
                  {{ item.influencer.user.full_name }}
                </router-link>
              </td>
              <td class="word-no-wrap text-right">{{ item.clicks }}</td>
              <td class="word-no-wrap text-right">{{ item.unique_clicks }}</td>
              <td class="word-no-wrap text-right">{{ item.sales }}</td>
              <td class="word-no-wrap text-right">DKK {{ item.value | local_numbers }}</td>
              <td class="word-no-wrap text-right">DKK {{ item.commission | local_numbers }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import local_numbers from "@/helpers/filters/local_numbers";

export default {
  filters: {
    utc_to_local_datetime,
    local_numbers
  },
  props: {
    influencers: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showNewInvoiceDialog: false,
    headers: [
      {
        text: "",
        sortable: false
      },
      {
        text: "Influencer",
        align: "left",
        sortable: false
      },
      {
        text: "Clicks",
        align: "right",
        sortable: false
      },
      {
        text: "Unique clicks",
        align: "right",
        sortable: false
      },
      {
        text: "Conversions",
        align: "right",
        sortable: false
      },
      {
        text: "Revenue",
        align: "right",
        sortable: false
      },
      {
        text: "Commission",
        align: "right",
        sortable: false
      }
    ]
  })
};
</script>
