<template>
  <div>
    <v-card>
      <v-card-title>
        Invoices
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="invoices"
          hide-default-footer
          :items-per-page="10"
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  {{ item.invoice_key }}
                </td>
                <td>{{ item.type }}</td>
                <td class="word-no-wrap">
                  {{ item.date | utc_to_local_datetime("DD-MM-YYYY") }}
                </td>
                <td :class="statusColor(item.status)">{{ item.status }}</td>
                <td class="text-right">DKK {{ item.total | local_numbers }}</td>
                <td class="text-right">
                  <v-btn
                    icon
                    outlined
                    color="primary"
                    class="mr-3"
                    title="View"
                    :href="item.view_url"
                    target="_blank"
                  >
                    <v-icon small>fal fa-eye</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    outlined
                    color="primary"
                    title="Download"
                    :href="item.download_url"
                    target="_blank"
                  >
                    <v-icon small>fal fa-file-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="showNewInvoiceDialog = true;" outlined>
          Create invoice
        </v-btn>
      </v-card-actions>
    </v-card>

    <business-new-invoice-dialog
      v-model="showNewInvoiceDialog"
      :business="business"
    ></business-new-invoice-dialog>
  </div>
</template>

<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import local_numbers from "@/helpers/filters/local_numbers";
import BusinessNewInvoiceDialog from "../dialogs/BusinessNewInvoiceDialog";

export default {
  filters: {
    utc_to_local_datetime,
    local_numbers
  },
  props: {
    invoices: {
      type: Array,
      required: true
    },
    business: {
      type: Object,
      required: true
    }
  },
  components: {
    BusinessNewInvoiceDialog
  },
  data: () => ({
    showNewInvoiceDialog: false,
    headers: [
      {
        text: "Invoice id",
        align: "left",
        sortable: false,
        value: "invoice_key"
      },
      {
        text: "Type",
        align: "left",
        sortable: false,
        value: "type"
      },
      {
        text: "Finalized",
        align: "left",
        sortable: false,
        value: "date"
      },
      {
        text: "Status",
        align: "left",
        sortable: false,
        value: "invoice_key"
      },
      {
        text: "Amount",
        align: "right",
        sortable: false,
        value: "total"
      },
      {
        text: "",
        align: "right",
        sortable: false,
      }
    ]
  }),
  methods: {
    statusColor(status) {
      return status === "paid"
        ? "success--text"
        : status === "open"
        ? "warning--text"
        : "error--text";
    }
  }
};
</script>
